import './App.css'
import Drivers from "./Points_systems/Drivers"
import FrontPage from "./FrontPage"

function App() {
    return (
        <div className="App">
            <FrontPage/>
            <hr/>
            <Drivers/>
        </div>
    )
}

export default App
